<template>
    <TaskList
        main
        extendDrawer
        showFilter
        :isScroll="true"
        model="tasks.TaskModel"
        tableType="chat_tasks"
        :pageName="pageName"
        :columnNameWidth="200"
        :pageConfig="pageConfig"
        :hash="false"
        :actionFix="false"
        :formParams="formParams"
        :queryParams="queryParams"
        :name="`chat_${id}`">
        <PageFilter 
            model="tasks.TaskModel"
            :key="pageName"
            size="large"
            :excludeFields="excludeFields"
            :page_name="pageName"/>
    </TaskList>
</template>

<script>
import PageFilter from '@/components/PageFilter'
import TaskList from '@apps/vue2TaskComponent/components/TaskList/TaskList'
export default {
    props: {
        id: {
            type: [String, Number],
            required: true
        }
    },
    components: {
        TaskList,
        PageFilter
    },
    computed:{
        pageName() {
            return `tasks.chat_${this.id}`
        }
    },
    created(){
        this.queryParams = {filters: {message_share__chat: this.id}}
        this.queryParams['page_name'] = this.pageName
        //this.excludeFields = ['workgroup']
        this.render = true
    },
    data(){
        return{
            formParams: {},
            queryParams: {},
            excludeFields: [],
            // page_name: "tasks.groups_and_project",
            render: false,
            pageConfig: {
                showFilter: true
            }
        }
    }
}
</script>